<template>
  <form @keydown="form.onKeydown($event)" @submit.prevent="editCarResponsible" v-if="onProgress === false">
    <div class="row">
      <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('creator_id') }">
        <div class="form-group">
          <multiselect :allow-empty="false"
                       :options="employees"
                       :searchable="true"
                       @input="() => {
                         form.creator_id = car_responsible ? car_responsible.id : null;
                       }"
                       deselect-label="Убрать"
                       label="title"
                       placeholder="Ответственный"
                       select-label="Выбрать"
                       selected-label="Выбрано"
                       title="Ответственный"
                       track-by="id"
                       required
                       v-model="car_responsible">
            <template slot="noResult">Не найдено</template>
            <template slot="noOptions">Данные отсутствуют</template>
          </multiselect>
          <HasError :form="form" field="creator_id"/>
        </div>

        <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

        <Button :disabled="!validateData || form.busy"
                :form="form"
                :loading="form.busy"
                class="btn btn-lg btn-success pull-right"
                style="min-width: 100px;">
          <i class="fa fa-save"></i>
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
import Form from "vform";
import Multiselect from 'vue-multiselect';

Form.axios = API.apiClient;

export default {
  name: 'edit-car-responsible-form',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    car: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: new Form({
      creator_id: null,
    }),

    employees: [],
    car_responsible: null,

    onProgress: true,
  }),

  async created() {
    try {
      this.car_responsible = this.car.creator;

      const employees_response = await API.apiClient.get('/employees', {
        params: this.$apiRequestParams.filters_list
      });

      this.employees = employees_response.data;
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.car_responsible && this.car_responsible.id !== this.car.creator.id;
    },
  },

  methods: {
    async editCarResponsible() {
      await this.form.put('/car/' + this.car.id + '/responsible').then(() => {
        showSuccess();
        this.EventBus.$emit('car-history-reload');
        this.EventBus.$emit('car-details-reload');
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style>
.multiselect__content-wrapper {
  position: relative;
}
</style>